body {
  margin: 0;
  padding: 0;
  font-family: 'Questrial', sans-serif;

}

header {
  color: #000;
  background-color: #fff !important;
}

@media print {
	a[role="button"],
	div.headroom-wrapper,
	main>div:first-child {
		display:none;
	}
}